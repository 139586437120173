import React from "react";
import "animate.css";
import ImagenTextQuienesEN from "./ImagenTextQuienesEN";

const BiografiaContenidoEN = () => {
  return (
    <>
      <div>
        <ImagenTextQuienesEN />
      </div>
    </>
  );
};

export default BiografiaContenidoEN;
