import React from "react";
import "animate.css";
import ImagenTextQuienes from "./ImagenTextQuienes";

const BiografiaContenido = () => {
  return (
    <>
      <div>
        <ImagenTextQuienes />
      </div>
    </>
  );
};

export default BiografiaContenido;
