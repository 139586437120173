import React from "react";
import "animate.css";

import ImagenTextQuienesFR from "./ImagenTextQuienesFR";

const BiografiaContenidoFR = () => {
  return (
    <>
      <div>
        <ImagenTextQuienesFR />
      </div>
    </>
  );
};

export default BiografiaContenidoFR;
